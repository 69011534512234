// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-about-the-plan-js": () => import("./../src/pages/about-the-plan.js" /* webpackChunkName: "component---src-pages-about-the-plan-js" */),
  "component---src-pages-about-us-js": () => import("./../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-economistsstatement-js": () => import("./../src/pages/economistsstatement.js" /* webpackChunkName: "component---src-pages-economistsstatement-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-supporters-js": () => import("./../src/pages/supporters.js" /* webpackChunkName: "component---src-pages-supporters-js" */)
}

